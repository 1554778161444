import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "object-types"
    }}>{`Object Types`}</h1>
    <hr></hr>
    <p>{`Object is another fundamental part of JavaScript language. With object you can represent any composed data types as sequence of properties and values.
You can create object type in Hegel by using curly braces {} and name-value pairs using a colon `}<inlineCode parentName="p">{`:`}</inlineCode>{` split by commas `}<inlineCode parentName="p">{`,`}</inlineCode>{` (the same as in JavaScript).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const user: { email: string; password: string } = {
  email: "any.user@gmail.com",
  password: "qwerty",
};
`}</code></pre>
    <h2 {...{
      "id": "strict-and-soft-object-types"
    }}>{`Strict and Soft object types`}</h2>
    <p>{`Defined object type is strict by default, it means that you cannot define additional property in object literal if it was not defined inside object type.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`/* Error: Type "{
  email: 'any.user@gmail.com',
  password: 'qwerty',
  status: 'offline'
}" is incompatible with type "{ email: string, password: string }" */
const strictUser: { email: string; password: string } = {
  email: "any.user@gmail.com",
  password: "qwerty",
  status: "offline",
};
`}</code></pre>
    <p>{`To make defined object type as soft you can add special statement `}<inlineCode parentName="p">{`...`}</inlineCode>{` (three dots). This statement will give Hegel information that you want to include additional properties inside object type.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const softUser: { email: string, password: string, ... } = { // 👌!
  email: "any.user@gmail.com",
  password: "qwerty",
  status: "offline"
};
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If you are familiar with Flow.js, in Flow.js this two examples (strict and soft object types) will look like this:`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const strictUser: {| email: string, password: string |} = { // 👌!
  name: "any.user@gmail.com",
  password: "qwerty"
};
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const softUser: { email: string; password: string } = {
  // 👌!
  name: "any.user@gmail.com",
  password: "qwerty",
  status: "offline",
};
`}</code></pre>
    <h2 {...{
      "id": "optional-object-type-properties"
    }}>{`Optional object type properties`}</h2>
    <p>{`As was mentioned in `}<a parentName="p" {...{
        "href": "/docs/optional-types"
      }}>{`Optional Types`}</a>{`, you can define object properties as optional by adding `}<inlineCode parentName="p">{`?`}</inlineCode>{`(question mark) before the type name.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const user: { email: string; password: ?string } = {
  email: "any.user@gmail.com",
}; // 👌!
`}</code></pre>
    <p>{`The logic is next - if you try to access to property which does not exist in object then value of this property will be undefined. Optional Types annotate that type of argument/variable/property can have annotated type value or undefined, so, undefined will be a valid value for this property.`}</p>
    <blockquote>
      <p parentName="blockquote">{`If you are familiar with Flow.js or TypeScript, you may know about similar optional property syntax inside this two "analyzers", which look like this:`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const user: { email: string; password?: string } = {
  email: "any.user@gmail.com",
}; // 👌!
`}</code></pre>
    <p>{`But Hegel doesn't have this syntax and define the same syntax for two situations (when your property is optional and when your property can contain undefined.`}</p>
    <h2 {...{
      "id": "object"
    }}>{`Object`}</h2>
    <p>{`Also, Hegel supports `}<inlineCode parentName="p">{`Object`}</inlineCode>{` type which represents any possible object in JavaScript:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function json(obj: Object) {
  return JSON.stringify(obj);
}

let result = json({ baz: 3.14, bar: "hello" }); // 👌!
result = json({}); // 👌!
result = json(new Object()); // 👌!
// Because Array is an object in JavaScript
result = json([]); // 👌!
// Because Function is an object in JavaScript
result = json(() => 2); // 👌!

// Error: Type "2" is incompatible with type "Object"
result = json(2);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      